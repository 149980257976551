<template>
    <div>
        <a
            :class="[open ? 'active open' : '', 'level-2 has-arrow waves-effect waves-dark mt-1']"
            href="javascript:void(0)"
            @click="toggleOpen"
        >
            <i class="fas fa-plus"></i>
            <span class="hide-menu ml-2">{{ menuLevel2.text }}</span>
        </a>
        <ul v-if="open">
            <li v-for="(child, index) in menuLevel2.childs" :key="index">
                <MenuLevel3Vue :menuLevel3="child" />
            </li>
        </ul>
    </div>
</template>

<script>
import MenuLevel3Vue from "./MenuLevel3.vue"

export default {
    props: { menuLevel2: Object },
    name: "MenuLevel2",
    components: {
        MenuLevel3Vue
    },
    computed: {
        routerPath() {
            return this.$route.path
        },
        isChosen() {
            const childs = this.menuLevel2.childs
            return childs.some(child => {
                return child.to == this.routerPath
            })
        }
    },
    data() {
        return {
            open: false
        }
    },
    methods: {
        toggleOpen() {
            this.open = !this.open
        }
    },
    created() {
        this.open = this.isChosen
    }
}
</script>

<style lang="css" scoped>
.level-2 {
    font-size: 13px;
}
.active.level-2 {
    background-color: rgba(0, 0, 0, 0.15) !important;
}
</style>
