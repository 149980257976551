<template>
    <!-- nếu mobile thì minisibare luôn true, toggle show-sidebar -->
    <!-- nếu notmobile thì toggle minisibare , show-sidebar false -->
    <div
        :class="[
            themeSkin,
            'fixed-layout',
            isMobile ? { 'mini-sidebar': true, 'show-sidebar': isMiniSidebar } : { 'show-sidebar': false }
        ]"
        id="main-wrapper"
    >
        <top-bar v-if="isMobile"></top-bar>
        <left-sidebar v-if="!isMobile || isMiniSidebar"></left-sidebar>
        <div class="page-wrapper">
            <div v-if="isMobile && isMiniSidebar" @click="toggleSideBar" class="overlay"></div>
            <div :class="[isMobile ? 'p-2' : 'pl-0 py-2 pr-2']">
                <maintenance v-if="isMaintenance"></maintenance>
                <div v-else class="container-fluid">
                    <router-view v-if="!isMaintenance"></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopBar from "./top-sidebar"
import LeftSidebar from "./left-sidebar"
import maintenance from "./maintenance"
import { APP_IS_MIMI_SIDEBAR } from "@/store/types"
import menus from "@/config/menu"
export default {
    components: {
        TopBar,
        LeftSidebar,
        maintenance
    },
    data() {
        return { menus: menus }
    },
    computed: {
        isMiniSidebar() {
            return this.$store.state.app.isMiniSidebar
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        themeSkin() {
            let theme =
                this.$store.state.site && this.$store.state.site.site && this.$store.state.site.site.theme
                    ? this.$store.state.site.site.theme
                    : "skin-blue"
            if (
                theme == "default-dark-theme" ||
                theme == "megna-dark-theme" ||
                theme == "green-dark-theme" ||
                theme == "skin-red-dark" ||
                theme == "skin-purple-dark"
            ) {
                return "skin-blue"
            }
            return theme
        },
        isMaintenance() {
            if (this.currentTool && this.currentTool.status === 0) {
                return true
            } else return false
        },
        tools() {
            return this.$store.state.tools.tools || null
        },
        currentTool() {
            let curent_tool = null
            if (this.tools) {
                for (const menu of this.menus) {
                    if (menu.childs) {
                        let menuCurent = menu.childs.find(item => item.to == this.$route.path)
                        if (menuCurent) {
                            for (const tool of this.tools) {
                                curent_tool = tool.prices.find(item => item.package_name == menuCurent.tool_alias)
                                if (curent_tool) {
                                    curent_tool.status = menuCurent.isMaintenance || menuCurent.is_hidden ? 0 : 1
                                    break
                                }
                            }
                            break
                        }
                    }
                }
            }
            return curent_tool
        }
    },
    methods: {
        toggleSideBar: function() {
            if (this.isMobile && this.isMiniSidebar) {
                this.$store.commit(APP_IS_MIMI_SIDEBAR)
            }
        }
    }
}
</script>

<style scoped>
.footer {
    position: fixed;
}
.overlay {
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 12; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}
</style>
