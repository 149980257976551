const menus = [
    {
        icon: "fas fa-user-shield",
        text: "Admin",
        admin: true,
        childs: [
            {
                to: "/statistics",
                text: "Thống kê trang",
                admin: true
            },
            {
                to: "/site-config",
                text: "Cài đặt trang",
                admin: true
            },
            {
                to: "/site-menu",
                text: "Thêm dịch vụ order",
                admin: true
            },
            {
                to: "/site-payment-card",
                text: "Cài đặt auto nạp thẻ",
                payment_card: true,
                admin: true
            },
            {
                to: "/landing-page",
                text: "Cài đặt html, css trang chủ (landing page)",
                admin: true
            },
            {
                to: "/notification",
                text: "Cài đặt thông báo",
                admin: true
            },
            {
                to: "/refund",
                text: "Quản lý hoàn tiền",
                admin: true
            },
            {
                to: "/clients",
                text: "Quản lý người dùng",
                admin: true
            },
            {
                to: "/error-order-report",
                text: "Nhật ký tạo đơn lỗi",
                admin: true
            }
        ]
    },
    // {
    //     icon: '<i class="fas fa-user-shield"></i>',
    //     text: "Admin",
    //     admin: true,
    //     href: "https://agency-admin.mfb.vn"
    // },
    {
        icon: "fas fa-home",
        text: "Home",
        childs: [
            {
                icon: "fas fa-home",
                to: "/",
                text: "Trang chủ"
            },
            {
                to: "/user-payment",
                text: "Nạp tiền"
            },
            {
                to: "/report",
                text: "Nhật ký hoạt động"
            },
            {
                to: "/agency-manager",
                text: "Tích hợp Site đại lý",
                // admin: true,
                active_site: true
            }
        ]
    },
    // SEEDING

    // ------------------------------------------- config menu facebook -------------
    {
        icon: "fab fa-facebook-square",
        text: "Facebook",
        tool_alias: "Facebook Seeding",
        childs: [
            {
                id: "facebook-corona",
                icon: "fab fa-facebook-square",
                text: "Server Người Thật",
                tool_alias: "Facebook Seeding",
                to: "#",
                childs: [
                    {
                        to: "/facebook-like",
                        text: "Buff Like Bài Viết",
                        name: "Facebook Seeding",
                        badge: false,
                        icon: "fas fa-star-half-alt",
                        tool_alias: "seeding_by_workers_like",
                        type: "like"
                    },
                    {
                        to: "/facebook-like-comment",
                        text: "Buff Like Comment",
                        name: "Facebook Seeding",
                        badge: false,
                        icon: "fas fa-star-half-alt",
                        tool_alias: "seeding_by_workers_like_comment",
                        type: "like_comment"
                    },
                    {
                        to: "/facebook-comment",
                        text: "Buff Comment Bài Viết",
                        name: "Facebook Seeding",
                        badge: false,
                        icon: "fas fa-star-half-alt",
                        tool_alias: "seeding_by_workers_comment",
                        type: "comment"
                    },
                    {
                        to: "/facebook-share",
                        text: "Buff Share Bài Viết",
                        name: "Facebook Seeding",
                        badge: false,
                        icon: "fas fa-star-half-alt",
                        tool_alias: "seeding_by_workers_share",
                        type: "share"
                    },
                    {
                        to: "/facebook-follow",
                        text: "Buff Sub (Tăng Theo Dõi)",
                        name: "Facebook Seeding",
                        badge: false,
                        icon: "fa fa-user-circle-o",
                        tool_alias: "seeding_by_workers_follow",
                        type: "follow"
                    },
                    {
                        to: "/facebook-review",
                        text: "Buff Review (Tăng Đánh Giá Fanpage)",
                        name: "Facebook Seeding",
                        badge: false,
                        icon: "fa fa-user-circle-o",
                        tool_alias: "seeding_by_workers_like_page",
                        type: "review"
                    },
                    {
                        to: "/facebook-like-page",
                        text: "Buff Like Fanpage Order",
                        name: "Facebook Seeding",
                        badge: false,
                        icon: "far fa-thumbs-up",
                        tool_alias: "seeding_by_workers_like_page",
                        type: "like"
                    },
                    // {
                    //     to: '/buff-like-fanpage',
                    //     text: 'Mời Bạn Bè Like FanPage',
                    //     name: 'Mời Bạn Bè Like FanPage',
                    //     badge: false,
                    //     icon: 'fa fa-hand-o-up',
                    //     tool_alias: 'buff_like_fan_page',
                    //     type: 'like'
                    // },
                    // {
                    //     to: "/eye-view-v2",
                    //     text: "Buff View Video v2",
                    //     name: "Buff View Video v2",
                    //     icon: "fa fa-eye",
                    //     badge: true,
                    //     // tool_alias: "buff_view_sv_v2",
                    //     type: "view"
                    // },
                    {
                        to: "/facebook-view",
                        text: "Buff View Video",
                        name: "Buff View Video",
                        icon: "fas fa-video",
                        badge: true,
                        tool_alias: "fb_view",
                        type: "view"
                    },
                    {
                        to: "/eye-livestream-v2",
                        text: "Buff Mắt Live Stream v2",
                        name: "Buff Mắt Live Stream v2",
                        icon: "fa fa-eye",
                        badge: true,
                        tool_alias: "buff_eyes_sv_v2",
                        type: "mắt"
                    }
                    // {
                    //     to: '/buff-view-kg',
                    //     text: 'Buff View Video v1',
                    //     name: 'Buff View Video v1',
                    //     icon: 'fa fa-eye',
                    //     badge: true,
                    //     tool_alias: 'buff_view_sv_kg',
                    //     type: 'view'
                    // },
                    // {
                    //     to: "/eye-livestream-v1",
                    //     text: "Buff Mắt Live Stream v1",
                    //     name: "Buff Mắt Live Stream v1",
                    //     icon: "fa fa-eye",
                    //     badge: true,
                    //     tool_alias: "buff_eyes_sv_kg",
                    //     type: "mắt"
                    // }
                ]
            },
            {
                id: "facebook-corona",
                icon: "fab fa-facebook-square",
                text: "Server Auto",
                tool_alias: "Facebook Seeding",
                to: "#",
                childs: [
                    {
                        to: "/facebook-like-corona",
                        text: "Buff Like Corona",
                        name: "Facebook Seeding",
                        badge: false,
                        icon: "fas fa-star-half-alt",
                        tool_alias: "seeding_by_workers_like",
                        type: "like"
                    },
                    {
                        to: "/facebook-follow-corona",
                        text: "Buff Follow Corona",
                        name: "Facebook Seeding",
                        badge: false,
                        icon: "fa fa-user-circle-o",
                        tool_alias: "seeding_by_workers_follow",
                        type: "follow"
                    },
                    {
                        to: "/facebook-like-page-corona",
                        text: "Buff Like Fanpage Corona",
                        name: "Facebook Seeding",
                        badge: false,
                        icon: "far fa-thumbs-up",
                        tool_alias: "seeding_by_workers_like_page",
                        type: "like"
                    },
                    {
                        to: "/facebook-join-group-corona",
                        text: "Buff Join Group Corona",
                        name: "Facebook Seeding",
                        badge: false,
                        icon: "far fa-thumbs-up",
                        tool_alias: "seeding_by_workers_join_group_corona",
                        type: "join_group_corona"
                    },
                    {
                        to: "/facebook-share-corona",
                        text: "Buff Share Corona",
                        name: "Facebook Seeding",
                        badge: false,
                        icon: "far fa-thumbs-up",
                        tool_alias: "seeding_by_workers_share_page",
                        type: "share"
                    }
                ]
            },
            {
                id: "facebook-corona",
                icon: "fab fa-facebook-square",
                text: "Facebook Vip",
                tool_alias: "Facebook Vip Seeding",
                to: "#",
                childs: [
                    {
                        to: "/vip-like-clone",
                        text: "Vip Like Clone Giá Rẻ",
                        name: "Vip Like Clone Giá Rẻ",
                        badge: false,
                        icon: "far fa-hand-point-up",
                        tool_alias: "vip_seeding_like_clone",
                        type: "like/ tháng",
                        admin: false
                    },
                    {
                        to: "/vip_like_sv3",
                        text: "Vip Like SV3",
                        name: "Vip Like SV3",
                        badge: false,
                        icon: "far fa-hand-point-up",
                        tool_alias: "vip_seeding_like_sv3",
                        type: "like/ tháng",
                        admin: false
                    },
                    {
                        to: "/vip-like-month",
                        text: "Vip Like Bài Viết Tháng",
                        name: "Facebook Vip Seeding",
                        badge: false,
                        icon: "fa fa-coffee",
                        tool_alias: "vip_seeding_like",
                        type: "like"
                    },
                    {
                        to: "/vip-like-sl",
                        text: "Vip Like Bài Viết Theo Số Lượng",
                        name: "Facebook Vip Seeding",
                        badge: false,
                        icon: "fab fa-staylinked",
                        tool_alias: "vip_seeding_like_sl",
                        type: "like"
                    },
                    {
                        to: "/vip-like-reaction",
                        text: "Vip Cảm Xúc Bài Viết Tháng",
                        name: "Vip Reaction Seeding",
                        badge: false,
                        icon: "far fa-heart",
                        tool_alias: "seeding_by_workers_like_reaction",
                        type: "like"
                    },
                    {
                        to: "/vip-comment",
                        text: "Vip Bình Luận Bài Viết Tháng",
                        name: "Facebook Vip Seeding",
                        badge: false,
                        icon: "fas fa-comment-dots",
                        tool_alias: "vip_seeding_comment",
                        type: "bình luận"
                    }
                    // {
                    //     to: "/vip-live-stream-pro",
                    //     text: "Vip Tăng Mắt LiveStream Tháng v2",
                    //     name: "Vip Mắt LiveStream",
                    //     icon: "fa fa-eye",
                    //     badge: true,
                    //     tool_alias: "vip_eyes_live_stream_v2",
                    //     type: "mắt"
                    // },
                    // {
                    //     to: "/vip-live-stream-kg",
                    //     text: "Vip Tăng Mắt LiveStream Tháng v1",
                    //     name: "Vip Mắt LiveStream",
                    //     icon: "fa fa-eye",
                    //     badge: true,
                    //     tool_alias: "vip_eyes_live_stream",
                    //     type: "mắt"
                    // }
                ]
            }
        ]
    },
    // ------------------------------------------- end menu config -----------------

    // {
    //     icon: "fab fa-facebook-square",
    //     text: "Facebook Buff",
    //     tool_alias: "Facebook Seeding",
    //     childs: [
    //         {
    //             to: "/facebook-like",
    //             text: "Buff Like Bài Viết",
    //             name: "Facebook Seeding",
    //             badge: false,
    //             icon: "fas fa-star-half-alt",
    //             tool_alias: "seeding_by_workers_like",
    //             type: "like"
    //         },
    //         {
    //             to: "/facebook-like-comment",
    //             text: "Buff Like Comment",
    //             name: "Facebook Seeding",
    //             badge: false,
    //             icon: "fas fa-star-half-alt",
    //             tool_alias: "seeding_by_workers_like_comment",
    //             type: "like_comment"
    //         },
    //         {
    //             to: "/facebook-comment",
    //             text: "Buff Comment Bài Viết",
    //             name: "Facebook Seeding",
    //             badge: false,
    //             icon: "fas fa-star-half-alt",
    //             tool_alias: "seeding_by_workers_comment",
    //             type: "comment"
    //         },
    //         {
    //             to: "/facebook-share",
    //             text: "Buff Share Bài Viết",
    //             name: "Facebook Seeding",
    //             badge: false,
    //             icon: "fas fa-star-half-alt",
    //             tool_alias: "seeding_by_workers_share",
    //             type: "share"
    //         },
    //         {
    //             to: "/facebook-follow",
    //             text: "Buff Sub (Tăng Theo Dõi)",
    //             name: "Facebook Seeding",
    //             badge: false,
    //             icon: "fa fa-user-circle-o",
    //             tool_alias: "seeding_by_workers_follow",
    //             type: "follow"
    //         },
    //         {
    //             to: "/facebook-review",
    //             text: "Buff Review (Tăng Đánh Giá Fanpage)",
    //             name: "Facebook Seeding",
    //             badge: false,
    //             icon: "fa fa-user-circle-o",
    //             tool_alias: "seeding_by_workers_like_page",
    //             type: "review"
    //         },
    //         {
    //             to: "/facebook-like-page",
    //             text: "Buff Like Fanpage Order",
    //             name: "Facebook Seeding",
    //             badge: false,
    //             icon: "far fa-thumbs-up",
    //             tool_alias: "seeding_by_workers_like_page",
    //             type: "like"
    //         },
    //         {
    //             to: "/facebook-view",
    //             text: "Buff View Video",
    //             name: "Buff View Video",
    //             icon: "fas fa-video",
    //             badge: true,
    //             tool_alias: "fb_view",
    //             type: "view"
    //         },
    //         {
    //             to: "/eye-livestream-v2",
    //             text: "Buff Mắt Live Stream v2",
    //             name: "Buff Mắt Live Stream v2",
    //             icon: "fa fa-eye",
    //             badge: true,
    //             tool_alias: "buff_eyes_sv_v2",
    //             type: "mắt"
    //         }
    //     ]
    // },

    // {
    //     icon: "fab fa-facebook-square",
    //     text: "Facebook Corona",
    //     tool_alias: "Facebook Seeding",
    //     childs: [
    //         {
    //             to: "/facebook-like-corona",
    //             text: "Buff Like Corona",
    //             name: "Facebook Seeding",
    //             badge: false,
    //             icon: "fas fa-star-half-alt",
    //             tool_alias: "seeding_by_workers_like",
    //             type: "like"
    //         },
    //         {
    //             to: "/facebook-follow-corona",
    //             text: "Buff Follow Corona",
    //             name: "Facebook Seeding",
    //             badge: false,
    //             icon: "fa fa-user-circle-o",
    //             tool_alias: "seeding_by_workers_follow",
    //             type: "follow"
    //         },
    //         {
    //             to: "/facebook-like-page-corona",
    //             text: "Buff Like Fanpage Corona",
    //             name: "Facebook Seeding",
    //             badge: false,
    //             icon: "far fa-thumbs-up",
    //             tool_alias: "seeding_by_workers_like_page",
    //             type: "like"
    //         }
    //     ]
    // },
    // {
    //     icon: "fab fa-facebook-square",
    //     text: "Facebook Giá Rẻ",
    //     tool_alias: "Facebook SV5",
    //     id: "facebook-sv5",
    //     is_hidden: true
    // },

    // --------------------------- server auto -------------
    // {
    //     icon: "fab fa-facebook-square",
    //     text: "Facebook Auto",
    //     tool_alias: "Facebook Auto",
    //     id: "facebook-auto",
    //     childs: [
    //         {
    //             id: "facebook-corona",
    //             icon: "fab fa-facebook-square",
    //             text: "Facebook Corona",
    //             tool_alias: "Facebook Seeding",
    //             to: "#",
    //             childs: [
    //                 {
    //                     to: "/facebook-like-corona",
    //                     text: "Buff Like Corona",
    //                     name: "Facebook Seeding",
    //                     badge: false,
    //                     icon: "fas fa-star-half-alt",
    //                     tool_alias: "seeding_by_workers_like",
    //                     type: "like"
    //                 },
    //                 {
    //                     to: "/facebook-follow-corona",
    //                     text: "Buff Follow Corona",
    //                     name: "Facebook Seeding",
    //                     badge: false,
    //                     icon: "fa fa-user-circle-o",
    //                     tool_alias: "seeding_by_workers_follow",
    //                     type: "follow"
    //                 },
    //                 {
    //                     to: "/facebook-like-page-corona",
    //                     text: "Buff Like Fanpage Corona",
    //                     name: "Facebook Seeding",
    //                     badge: false,
    //                     icon: "far fa-thumbs-up",
    //                     tool_alias: "seeding_by_workers_like_page",
    //                     type: "like"
    //                 }
    //             ]
    //         },
    //         {
    //             id: "facebook-sv3",
    //             icon: "fab fa-facebook-square",
    //             text: "Facebook Giá Rẻ",
    //             tool_alias: "Facebook SV4",
    //             to: "#",
    //             childs: [
    //                 {
    //                     to: "/facebook-like-sv3",
    //                     text: "Buff Like Giá Rẻ",
    //                     name: "Facebook Seeding",
    //                     badge: false,
    //                     icon: "fas fa-star-half-alt",
    //                     tool_alias: "like_sv3",
    //                     type: "like_sv3"
    //                 },
    //                 {
    //                     to: "/facebook-follow-sv3",
    //                     text: "Buff Follow Giá Rẻ",
    //                     name: "Facebook Seeding",
    //                     badge: false,
    //                     icon: "fa fa-user-circle-o",
    //                     tool_alias: "follow_sv3",
    //                     type: "follow_sv3"
    //                 },
    //                 {
    //                     to: "/facebook-like-page-sv3",
    //                     text: "Buff Like Page Giá Rẻ",
    //                     name: "Facebook Seeding",
    //                     badge: false,
    //                     icon: "far fa-thumbs-up",
    //                     tool_alias: "like_page_sv3",
    //                     type: "like_page_sv3"
    //                 }
    //             ]
    //         }
    //     ]
    // },

    // ------------------------ end server auto ----------------

    // ------------------------ server vip -----------------
    // {
    //     icon: "fab fa-facebook-square",
    //     text: "Facebook VIP",
    //     tool_alias: "Facebook Vip Seeding",
    //     childs: [
    //         {
    //             to: "/vip-like-clone",
    //             text: "Vip Like Clone Giá Rẻ",
    //             name: "Vip Like Clone Giá Rẻ",
    //             badge: false,
    //             icon: "far fa-hand-point-up",
    //             tool_alias: "vip_seeding_like_clone",
    //             type: "like/ tháng",
    //             admin: false
    //         },
    //         {
    //             to: "/vip-like-month",
    //             text: "Vip Like Bài Viết Tháng",
    //             name: "Facebook Vip Seeding",
    //             badge: false,
    //             icon: "fa fa-coffee",
    //             tool_alias: "vip_seeding_like",
    //             type: "like"
    //         },
    //         {
    //             to: "/vip-like-sl",
    //             text: "Vip Like Bài Viết Theo Số Lượng",
    //             name: "Facebook Vip Seeding",
    //             badge: false,
    //             icon: "fab fa-staylinked",
    //             tool_alias: "vip_seeding_like_sl",
    //             type: "like"
    //         },
    //         {
    //             to: "/vip-like-reaction",
    //             text: "Vip Cảm Xúc Bài Viết Tháng",
    //             name: "Vip Reaction Seeding",
    //             badge: false,
    //             icon: "far fa-heart",
    //             tool_alias: "seeding_by_workers_like_reaction",
    //             type: "like"
    //         },
    //         {
    //             to: "/vip-comment",
    //             text: "Vip Bình Luận Bài Viết Tháng",
    //             name: "Facebook Vip Seeding",
    //             badge: false,
    //             icon: "fas fa-comment-dots",
    //             tool_alias: "vip_seeding_comment",
    //             type: "bình luận"
    //         }
    //         // {
    //         //     to: "/vip-live-stream-pro",
    //         //     text: "Vip Tăng Mắt LiveStream Tháng v2",
    //         //     name: "Vip Mắt LiveStream",
    //         //     icon: "fa fa-eye",
    //         //     badge: true,
    //         //     tool_alias: "vip_eyes_live_stream_v2",
    //         //     type: "mắt"
    //         // },
    //         // {
    //         //     to: "/vip-live-stream-kg",
    //         //     text: "Vip Tăng Mắt LiveStream Tháng v1",
    //         //     name: "Vip Mắt LiveStream",
    //         //     icon: "fa fa-eye",
    //         //     badge: true,
    //         //     tool_alias: "vip_eyes_live_stream",
    //         //     type: "mắt"
    //         // }
    //     ]
    // },

    // --------------------------- end server vip ------------
    {
        icon: "fab fa-instagram",
        text: "Instagram Buff",
        tool_alias: "Instagram Seeding",
        childs: [
            {
                to: "/instagram-like",
                text: "Buff Like Instagram",
                name: "Instagram Seeding",
                badge: false,
                icon: "far fa-thumbs-up",
                tool_alias: "instagram_like",
                type: "like"
            },
            {
                to: "/instagram-follow",
                text: "Buff Follow Instagram",
                name: "Instagram Seeding",
                badge: false,
                icon: "fas fa-user-plus",
                tool_alias: "instagram_follow",
                type: "follow"
            },
            {
                to: "/instagram-comment",
                text: "Buff Comment Instagram",
                name: "Instagram Seeding",
                badge: false,
                icon: "far fa-comment-dots",
                tool_alias: "instagram_comment",
                type: "comment"
            }
        ]
    },
    {
        icon: "fab fa-tumblr",
        text: "Tiktok Buff",
        tool_alias: "TikTok Seeding",
        childs: [
            {
                to: "/tiktok-like",
                text: "Buff Like tiktok",
                name: "TikTok Seeding",
                badge: false,
                icon: "far fa-thumbs-up",
                tool_alias: "tiktok_like",
                type: "like"
            },
            {
                to: "/tiktok-follow",
                text: "Buff Follow tiktok",
                name: "TikTok Seeding",
                badge: false,
                icon: "fas fa-user-plus",
                tool_alias: "tiktok_follow",
                type: "follow"
            },
            {
                to: "/tiktok-view",
                text: "Buff View tiktok",
                name: "Seeding View",
                badge: false,
                icon: "fas fa-user-plus",
                tool_alias: "tiktok_view",
                type: "view"
            },
            {
                to: "/tiktok-comment",
                text: "Buff Comment tiktok",
                name: "TikTok Seeding",
                badge: false,
                icon: "far fa-comment-dots",
                tool_alias: "tiktok_comment",
                type: "comment"
            },
            {
                to: "/tiktok-favorites",
                text: "Buff Favorite tiktok",
                name: "Seeding Favorite",
                badge: false,
                icon: "far fa-thumbs-up",
                tool_alias: "tiktok_favorites",
                type: "favorites"
            },
            {
                to: "/tiktok-combo-like-sub",
                text: "Buff Combo Like Sub tiktok",
                name: "Seeding Combo Like Sub",
                badge: false,
                icon: "fas fa-user-plus",
                tool_alias: "tiktok_combo_like_sub",
                type: "combo_like_sub"
            }
        ]
    },
    {
        icon: "fab fa-twitter",
        text: "Twitter Buff",
        tool_alias: "Twitter",
        childs: [
            {
                to: "/twitter-like",
                text: "Buff Like Twitter",
                name: "Twitter Seeding",
                badge: false,
                icon: "far fa-thumbs-up",
                tool_alias: "twitter_like",
                type: "like"
            },
            {
                to: "/twitter-follow",
                text: "Buff Follow Twitter",
                name: "Twitter Seeding",
                badge: false,
                icon: "fas fa-user-plus",
                tool_alias: "twitter_follow",
                type: "follow"
            },
            {
                to: "/twitter-comment",
                text: "Buff Comment Twitter",
                name: "Twitter Seeding",
                badge: false,
                icon: "far fa-comment-dots",
                tool_alias: "twitter_comment",
                type: "comment"
            }
        ]
    },
    {
        icon: "fab fa-youtube",
        text: "Youtube Buff",
        tool_alias: "Youtube Seeding",
        childs: [
            {
                to: "/youtube-view",
                text: "Buff View Youtube",
                name: "Seeding View",
                badge: false,
                icon: "fas fa-user-plus",
                tool_alias: "youtube_view",
                type: "view"
            },
            {
                to: "/youtube-sub",
                text: "Buff Sub Channel",
                name: "Buff Sub Channel",
                badge: false,
                icon: "fas fa-user-check",
                tool_alias: "seeding_by_workers_subscribe_youtube",
                type: "sub"
            },
            // {
            //     to: "/youtube-view",
            //     text: "Buff View Video",
            //     name: "Youtube Seeding",
            //     badge: false,
            //     icon: "fas fa-eye",
            //     admin: false,
            //     tool_alias: "seeding_by_workers_view_youtube",
            //     type: "view"
            // },
            {
                to: "/youtube-comment",
                text: "Buff Comment Video",
                name: "Youtube Seeding",
                badge: false,
                icon: "fas fa-comments",
                tool_alias: "seeding_by_workers_comment_youtube",
                type: "comment"
            }
        ]
    },
    {
        icon: "fas fa-store-alt",
        text: "Shopee Buff",
        tool_alias: "Shopee",
        childs: [
            {
                to: "/shopee-like",
                text: "Buff Like Shopee",
                name: "Buff Like Shopee",
                badge: false,
                icon: "fas fa-user-plus",
                tool_alias: "shopee_like",
                type: "like"
            },
            {
                to: "/shopee-follow",
                text: "Buff Follow Shopee",
                name: "Buff Follow Shopee",
                badge: false,
                icon: "fas fa-eye",
                admin: false,
                tool_alias: "shopee_follow",
                type: "follow"
            }
        ]
    },
    {
        icon: "fas fa-shopping-basket",
        text: "Lazada Buff",
        tool_alias: "Lazada",
        childs: [
            {
                to: "/lazada-like",
                text: "Buff Like Lazada",
                name: "Lazada Seeding",
                badge: false,
                icon: "far fa-thumbs-up",
                tool_alias: "lazada_like",
                type: "like"
            },
            {
                to: "/lazada-follow",
                text: "Buff Follow Lazada",
                name: "Lazada Seeding",
                badge: false,
                icon: "fas fa-user-plus",
                tool_alias: "lazada_follow",
                type: "follow"
            }
        ]
    },
    {
        icon: "fab fa-google",
        text: "Traffic  Buff",
        tool_alias: "Seo Traffic",
        childs: [
            {
                to: "/traffics",
                text: "Google User Search",
                name: "Google User Search",
                badge: false,
                icon: "fab fa-google",
                tool_alias: "seo_traffic_1",
                type: "google"
            },
            {
                to: "/traffic-backlinks",
                text: "BackLink User",
                name: "BackLink User",
                badge: false,
                icon: "fas fa-link",
                admin: false,
                tool_alias: "seo_traffic_2",
                type: "backlink"
            },
            {
                to: "/traffic-redirect",
                text: "Traffic Direct",
                name: "Traffic Direct",
                badge: false,
                icon: "fas fa-link",
                admin: false,
                tool_alias: "seo_traffic_2",
                type: "redirect"
            }
        ]
    },
    {
        icon: "fab fa-google",
        text: "Review",
        tool_alias: "Review",
        childs: [
            {
                to: "/review-map",
                text: "Review Google Map",
                name: "Review Google Map",
                badge: false,
                icon: "fab fa-google",
                tool_alias: "review_maps"
                // type: "google"
            },
            {
                to: "/review-app",
                text: "Review Apps",
                name: "Review Apps",
                badge: false,
                icon: "fas fa-link",
                tool_alias: "review_apps"
                // type: "backlink"
            }
        ]
    },
    {
        icon: "fab fa-jedi-order",
        text: "Dịch vụ khác",
        childs: [
            {
                icon: "icon-social-facebook",
                to: "/spam-sms",
                name: "Auto Spam SMS",
                text: "Auto Spam SMS",
                tool_alias: "spam_sms",
                type: "SMS"
            },
            {
                icon: "icon-phone",
                to: "/uid-to-phone",
                text: "Tìm SĐT bằng Facebook",
                name: "Convert Uid To Phone",
                tool_alias: "convert_uid_to_phone",
                type: "SĐT"
            },
            {
                icon: "icon-social-facebook",
                to: "/phone-to-uid",
                name: "Convert SĐT To Uid",
                text: "Tìm Facebook bằng SĐT",
                tool_alias: "convert_phone_to_uid",
                type: "Uid"
            }
        ]
    }
]
export default menus
