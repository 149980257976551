<template>
    <div @click="sidebartoggler">
        <router-link :to="menuLevel3.to"
            ><div class="row">
                <div class="col-auto pr-0">
                    <i v-show="isChosen" :class="['fas fa-angle-double-right mr-1', isChosen ? 'active' : '']"></i>
                </div>
                <div :class="['col', !isChosen ? 'pl-4' : 'pl-2']">
                    <span :class="['font-13', !isChosen ? '' : 'active bold']"
                        >{{ menuLevel3.text }} {{ menuLevel3.isMaintenance ? "(bảo trì)" : "" }}</span
                    >
                </div>
            </div></router-link
        >
    </div>
</template>

<script>
import { APP_IS_MIMI_SIDEBAR } from "@/store/types"
export default {
    props: { menuLevel3: Object },
    name: "MenuLevel3",
    computed: {
        routerPath() {
            return this.$route.path
        },
        isChosen() {
            return this.routerPath == this.menuLevel3.to
        }
    },
    methods: {
        sidebartoggler() {
            this.$store.commit(APP_IS_MIMI_SIDEBAR)
        }
    }
}
</script>

<style lang="scss" scoped></style>
