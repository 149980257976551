<template>
    <div>
        <aside class="left-sidebar">
            <vue-perfect-scrollbar class="scroll-sidebar">
                <nav class="sidebar-nav">
                    <ul id="sidebarnav">
                        <router-link :to="'/'">
                            <li :class="['text-center', isMiniSidebar && !isMobile ? 'top-left-sidebar' : '', '']">
                                <img :src="logo" style="width:'60px'; height:60px" class="light-logo mt-2" />
                                <h4 class="site-name bold mt-3 mb-4">
                                    {{ site.site_header }}
                                </h4>
                            </li>
                        </router-link>
                        <li
                            class="mt-2"
                            v-show="(user.role == 'admin' || !menu.admin) && !menu.is_hidden"
                            v-for="(menu, index) in menus"
                            :key="index"
                            :class="menu.class"
                        >
                            <template v-if="menu.childs">
                                <a
                                    @click="handleSidebarExpandClick(menu)"
                                    :class="['mr-2', menu.open ? 'active' : '', 'has-arrow waves-effect waves-dark']"
                                    href="javascript:void(0)"
                                    aria-expanded="false"
                                >
                                    <i :class="[menu.icon, menu.open ? 'active' : '']"></i>
                                    <span class="hide-menu ml-2">{{ menu.text }}</span>
                                </a>
                                <ul aria-expanded="false" :class="['collapse mr-2', { in: menu.open }]">
                                    <li
                                        v-for="(child, index2) in menu.childs"
                                        v-show="
                                            (user.role == 'admin' && child.admin && !child.is_hidden) ||
                                                (!child.admin && !child.is_hidden && !child.active_site) ||
                                                (child.active_site && user.active_site)
                                        "
                                        @click="handleChildClick(child)"
                                        :key="index2"
                                    >
                                        <template v-if="!child.childs">
                                            <router-link
                                                :to="child.to"
                                                v-if="(site.payment_card && child.payment_card) || !child.payment_card"
                                            >
                                                <div class="row">
                                                    <div class="col-auto pr-0">
                                                        <i
                                                            v-show="child.open"
                                                            :class="[
                                                                'fas fa-angle-double-right mr-1',
                                                                child.open ? 'active' : ''
                                                            ]"
                                                        ></i>
                                                    </div>
                                                    <div :class="['col', !child.open ? 'pl-4' : 'pl-2']">
                                                        <span
                                                            :class="[
                                                                'font-13',
                                                                !child.open ? '' : 'active bold',
                                                                child.isMaintenance
                                                                    ? 'text-danger '
                                                                    : child.is_hidden
                                                                    ? 'text-danger '
                                                                    : ''
                                                            ]"
                                                            >{{ child.text }}
                                                            {{ child.isMaintenance ? "(bảo trì)" : "" }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </router-link>
                                        </template>
                                        <template v-else>
                                            <MenuLevel2Vue :menuLevel2="child" />
                                        </template>
                                    </li>
                                </ul>
                            </template>
                            <template v-else>
                                <div v-if="menu.to" @click="handleSidebarExpandClick(menu)">
                                    <router-link
                                        :to="menu.to"
                                        :class="[
                                            'mr-2',
                                            menu.open ? 'active' : '',
                                            'has-arrow waves-effect waves-dark'
                                        ]"
                                        href="javascript:void(0)"
                                        aria-expanded="false"
                                    >
                                        <span v-html="menu.icon" :class="[, menu.open ? 'active' : '']"></span>
                                        <span class="hide-menu ml-2">{{ menu.text }}</span>
                                    </router-link>
                                </div>
                                <div v-else>
                                    <a
                                        :href="menu.href"
                                        target="_blank"
                                        :class="[
                                            'mr-2',
                                            menu.open ? 'active' : '',
                                            'has-arrow waves-effect waves-dark'
                                        ]"
                                        aria-expanded="false"
                                    >
                                        <span v-html="menu.icon" :class="[, menu.open ? 'active' : '']"></span>
                                        <span class="hide-menu ml-2">{{ menu.text }}</span>
                                    </a>
                                </div>
                            </template>
                        </li>
                        <li v-if="user" :class="[isMiniSidebar && !isMobile ? 'bottom-left-sidebar' : '', '']">
                            <div class="container mt-4">
                                <div class="card card-bottom-left-sidebar">
                                    <div class="card-body">
                                        <h6 class="text-center">
                                            <img
                                                :src="
                                                    user.avatar_id
                                                        ? 'https://graph.facebook.com/' +
                                                          user.avatar_id +
                                                          '/picture?width=100&height=100'
                                                        : 'assets/images/users/avatar_fb.jpg'
                                                "
                                                alt="user-img"
                                                width="50"
                                                class="img-circle"
                                            />
                                        </h6>
                                        <h6 class="text-center hide-menu font-bold mt-3 mb-0">
                                            {{ user.name ? user.name : user.username }}
                                        </h6>
                                        <h4 class="hide-menu font-bold mb-0">
                                            <span
                                                v-if="user.role != 'user'"
                                                :class="['badge text-dark d-block user-level']"
                                                >{{ user.role }}</span
                                            >
                                            <span
                                                v-else-if="user.levels[0]"
                                                :class="['badge text-dark d-block user-level']"
                                                >{{ user.levels[0].name }}</span
                                            >
                                        </h4>
                                        <h5 class="text-center hide-menu bold mb-0">
                                            <number
                                                ref="number1"
                                                :from="0"
                                                :to="user.coin"
                                                :format="theFormat"
                                                :duration="1"
                                                :delay="0"
                                                easing="Power1.easeOut"
                                            />
                                        </h5>
                                        <hr />
                                        <router-link to="/user-profile" class="bold"
                                            ><i class="ti-user mr-2"></i>Tài Khoản</router-link
                                        >
                                        <router-link to="/user-payment" class="bold"
                                            ><i class="ti-wallet mr-2"></i>Nạp tiền</router-link
                                        >
                                        <router-link to="/user-notification" class="bold"
                                            ><i class="far fa-bell mr-2"></i>Thông báo</router-link
                                        >
                                        <a class="bold" @click="logout">
                                            <i class="fa fa-power-off mr-2"></i>Đăng xuất</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
            </vue-perfect-scrollbar>
        </aside>
    </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar"

import menus from "@/config/menu"
import { APP_IS_MIMI_SIDEBAR, STORE_SET_MENU_OUT_SITE_INDEX, APP_LAST_PHONE_POPUP } from "@/store/types"
import MenuLevel2Vue from "../components/menu/MenuLevel2.vue"
import moment from "moment"

export default {
    name: "left-sidebar",
    components: {
        VuePerfectScrollbar,
        MenuLevel2Vue
    },
    data() {
        return {
            menus: menus,
            isOpenMenuAvatar: false
        }
    },
    computed: {
        isMiniSidebar() {
            return this.$store.state.app.isMiniSidebar
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        lastPhonePopup() {
            return this.$store.state.app.lastPhonePopup
        },
        user() {
            return this.$store.state.user.user
        },
        site() {
            return this.$store.state.site.site
        },
        menusSite() {
            return this.$store.state.site.menus
        },
        logo() {
            return this.site.logo || "assets/images/logo-icon.png"
        },
        tools() {
            return this.$store.state.tools.tools
        },
        reload() {
            return this.$store.state.reload
        },
        router() {
            return this.$route.path
        }
    },
    watch: {
        tools() {
            this.menuConfig()
        },
        user() {
            this.menuConfig()
        },
        router() {
            this.formatMenu()
        }
    },
    async created() {
        const { user, lastPhonePopup } = this
        if (user.phone) return
        const checkDay = moment().isAfter(moment(lastPhonePopup), "day")
        if (checkDay) {
            const result = await this.$swal.fire({
                type: "warning",
                title: "Thông báo",
                html:
                    "Tài khoản của bạn hiện tại chưa có <b>Số điện thoại</b> <br> Vui lòng nhập <b>Số điện thoại</b> để nhận được các tin tức mới nhất",
                showCancelButton: true,
                confirmButtonText: "Đồng ý",
                cancelButtonText: "Để lúc khác"
            })
            this.$store.commit(APP_LAST_PHONE_POPUP, moment().format())
            if (result.value) {
                this.$router.push("/user-profile")
            }
        }
    },
    mounted() {
        this.formatMenu()
        this.menuConfig()
    },
    methods: {
        formatMenu() {
            if (this.menusSite && this.menusSite.length > 0) {
                for (const menu of this.menusSite) {
                    let menuPackage = {
                        to: "/out-site-v2?id=" + menu.id,
                        icon: menu.icon ? menu.icon : '<i class="fab fa-servicestack"></i>',
                        text: menu.name,
                        tool_alias: menu.alias_name,
                        id: menu.id
                    }
                    let checkMenu = this.menus.find(item => item.tool_alias == menuPackage.tool_alias)
                    if (!checkMenu) {
                        this.menus.push(menuPackage)
                    }
                }
            }
            this.menus = this.menus.map(menu => {
                if (menu.childs) {
                    menu.open = false
                    for (const child of menu.childs) {
                        if (this.$route.path === child.to) {
                            child.open = true
                            menu.open = true
                        } else child.open = false
                        if (child.childs) {
                            for (const child2 of child.childs) {
                                if (this.$route.path === child2.to) {
                                    child2.open = true
                                    menu.open = true
                                } else child.open = false
                            }
                        }
                    }
                } else {
                    // if (this.$route.path === menu.to) {
                    //     menu.open = true
                    // } else menu.open = false
                }
                return menu
            })
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                : 0
        },
        sidebartoggler() {
            this.$store.commit(APP_IS_MIMI_SIDEBAR)
        },
        logout: async function() {
            this.$store.dispatch("LOGOUT")
        },
        handleSidebarExpandClick(item) {
            this.menus = this.menus.map(menu => {
                if (menu.childs) {
                    if (item === menu) {
                        menu.open = !menu.open
                    } else menu.open = false
                } else {
                    if (this.$route.query.id && this.$route.query.id == menu.id) {
                        menu.open = !menu.open
                    } else menu.open = false
                }
                return menu
            })
            this.$store.commit(STORE_SET_MENU_OUT_SITE_INDEX, item)
        },
        toggleSideBar: function() {
            if (this.isMobile && this.isMiniSidebar) {
                this.$store.commit(APP_IS_MIMI_SIDEBAR)
            }
        },
        handleChildClick(child) {
            if (!child.childs) {
                this.menus = this.menus.map(menu => {
                    if (menu.childs) {
                        menu.childs = menu.childs.map(item => {
                            if (item === child) {
                                item.open = true
                            } else item.open = false
                            return item
                        })
                    }
                    return menu
                })
                this.toggleSideBar()
            }
        },
        openMenuAvatar() {
            this.isOpenMenuAvatar = !this.isOpenMenuAvatar
        },
        menuConfig: function() {
            let tools = this.tools
            this.menus = this.menus.map(menu => {
                if (menu.childs) {
                    let countHide = 0
                    let countMaintenance = 0
                    menu.childs.map(child => {
                        if (child.name) {
                            let toolName = child.name
                            let currentTool = tools.find(item => {
                                return item.name === toolName
                            })
                            if (!currentTool) {
                                toolName = child.tool_alias
                                currentTool = this.tools.find(item => {
                                    return item.tool_alias === toolName || item.package_name === toolName
                                })
                            }
                            if (currentTool) {
                                child.is_hidden = currentTool.status === -1 ? true : currentTool.is_hidden
                                if (child.is_hidden) {
                                    countHide++
                                }
                                if (currentTool.status === 0) {
                                    child.isMaintenance = true
                                    countMaintenance++
                                }
                                child.notes = currentTool.notes
                            }
                        }
                        return child
                    })
                    if (menu.childs.length === countHide) {
                        menu.is_hidden = true
                    } else menu.is_hidden = false
                    if (menu.childs.length === countMaintenance) {
                        menu.isMaintenance = true
                    } else menu.isMaintenance = false
                }
                if (menu.name) {
                    let toolName = menu.name
                    let currentTool = tools.find(item => {
                        return item.name === toolName
                    })
                    if (currentTool) {
                        if (currentTool.status === -1) {
                            menu.hide = true
                        }
                        if (currentTool.status === 0) {
                            menu.isMaintenance = true
                        }
                        menu.notes = currentTool.notes
                    }
                }
                return menu
            })
        }
    }
}
</script>
<style scoped>
.user-level {
    border-radius: 0;
}
.badge-secondary {
    color: #ffffff;
    background-color: #6f42c1;
}
.badge-dark {
    color: #ffffff;
    background-color: #4f5467;
}
.color-facebook {
    color: #4267b2 !important;
}
/* .top-left-sidebar {
    margin-left: 10px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
} */
</style>
